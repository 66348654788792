import { Slider, SliderLabel } from '@progress/kendo-react-inputs'
import { sizedUrl } from 'app/utils'
import { Collapsable } from 'components/collapsable'
import { SelectColor } from 'components/colors/component'
import { FacebookIcon } from 'components/icons/facebook'
import { InstagramIcon } from 'components/icons/instagram'
import { LinkedInIcon } from 'components/icons/linkedin'
import { RasaLogo } from 'components/icons/rasalogo'
import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { Upload } from 'components/icons/upload2'
import { YoutubeLogo } from 'components/icons/youtube'
import { UpgradeAnchorLink } from 'components/link'
import { Loading, SIZES } from 'components/loading'
import { noValidationNeeded, validateEmailAddress, validateOptionalUrl, validateOptionalUrlOrEmailAddress } from 'components/validation/validations'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as GenericRedux from 'generic/genericRedux'
import { isTruthy } from 'generic/utility'
import * as GA from 'google-analytics'
import isNil from 'lodash/isNil'
import * as React from 'react'
import { createRef } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import * as Router from 'router'
import { DEFAULT_PARTNER_IDENTIFIER, IMAGE } from 'shared_server_client/constants'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import { EditSectionProps } from '../components'
import { EmailAttributes, getAttribute, IMAGE_REMOVED } from '../constants'
import '../styles.scss'
import { XLogo } from '../../icons/twitter-x'
import { Threads } from '../../icons/threads'
import { BRAND_NAME } from '../../../constants'

const dropzoneRef: any = createRef()

interface EditSectionState {
  loadingImage: boolean,
  imageLoadingError: boolean,
  plan?: BillingPlan.BillingPlan,
  partnerIdentifier?: string
}

type FooterProps = GenericRedux.AllComponentPropsWithModal<any> & EditSectionProps

class FooterEditComponent extends React.Component<FooterProps, EditSectionState> {
  public static contextType = RasaContext
  private communityId: string = null

  constructor(props: FooterProps) {
    super(props)
    this.state = {
      loadingImage: false,
      imageLoadingError: false,
      partnerIdentifier: DEFAULT_PARTNER_IDENTIFIER
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({activeCommunity}) => {
      this.communityId = activeCommunity.communityId
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan) {
        this.setState({plan: activeCommunity.billingInfo.currentPlan, partnerIdentifier: activeCommunity.billingInfo.productSubscription.account_partner_identifier})
      }
    })
  }

  public render() {
    return (
    <div className="social-link-editor">
      <span className="title">Footer</span>
      <this.removeBranding/>
      <div className="section">
        <div className="block"><br></br>
          <div className="title">Social Links </div>
          <div className="social-link-wrapper">
            <div className="social-link-logo-wrapper">
              <XLogo svgwidth={16.48} svgheight={16.48} />
            </div>
            <Input value={getAttribute(this.props.data, EmailAttributes.twitterName) || ''}
                    onChange={(e) => this.props.onChange(EmailAttributes.twitterName, e.target.value)}
                    placeholder="https://twitter.com/username"/>
          </div><br></br>
          <div className="social-link-wrapper">
            <div className="social-link-logo-wrapper">
            <FacebookIcon svgwidth={16.48} svgheight={16.48}/>
            </div>
            <Input value={getAttribute(this.props.data, EmailAttributes.facebookName) || ''}
                    onChange={(e) => this.props.onChange(EmailAttributes.facebookName, e.target.value)}
                    placeholder="https://facebook.com/username"/>
          </div><br></br>
          <div className="social-link-wrapper">
            <div className="social-link-logo-wrapper">
              <InstagramIcon/>
            </div>
            <Input value={getAttribute(this.props.data, EmailAttributes.instagramName) || ''}
                    onChange={(e) => this.props.onChange(EmailAttributes.instagramName, e.target.value)}
                    placeholder="https://instagram.com/username"/>
          </div><br></br>
          <div className="social-link-wrapper">
            <div className="social-link-logo-wrapper">
              <LinkedInIcon/>
            </div>
            <Input value={getAttribute(this.props.data, EmailAttributes.linkedInName) || ''}
                    onChange={(e) => this.props.onChange(EmailAttributes.linkedInName, e.target.value)}
                    placeholder="https://linkedin.com/username"/>
          </div><br></br>
          <div className="social-link-wrapper">
            <div className="social-link-logo-wrapper">
              <YoutubeLogo svgwidth={16.48} svgheight={16.48} />
            </div>
            <Input value={getAttribute(this.props.data, EmailAttributes.youtubeName) || ''}
                    onChange={(e) => this.props.onChange(EmailAttributes.youtubeName, e.target.value)}
                    placeholder="https://youtube.com/channelname"/>
          </div><br></br>
          <div className="social-link-wrapper">
            <div className="social-link-logo-wrapper">
              <Threads svgwidth={16.48} svgheight={16.48}/>
            </div>
            <Input value={getAttribute(this.props.data, EmailAttributes.threadsName) || ''}
                   onChange={(e) => this.props.onChange(EmailAttributes.threadsName, e.target.value)}
                   placeholder="https://threads.com/username"/>
          </div>
          <br></br>
        </div>
      </div>
      <div className="section">
        <div className="block">
          <Collapsable title="Additional Links">
            <div className="additional-links-container">
              {this.linkComponent('contact')}
              {this.linkComponent('learnMore')}
              {this.linkComponent('subscribe')}
              {this.linkComponent('feedback')}
            </div>
          </Collapsable>
        </div>
      </div>
      <div className="section">
      <div className="block">
          <div className="title">Footer Text</div>
          <div>
            <span>Text #1</span>
            <Input value={getAttribute(this.props.data, EmailAttributes.footerTextFieldOne) || ''}
                   maxLength={100}
                   onChange={(e) => this.props.onChange(EmailAttributes.footerTextFieldOne, e.target.value)}/>
          </div>
          <div>
            <span>Text #2</span>
            <Input value={getAttribute(this.props.data, EmailAttributes.footerTextFieldTwo) || ''}
                   maxLength={100}
                   onChange={(e) => this.props.onChange(EmailAttributes.footerTextFieldTwo, e.target.value)}/>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="footer-logo-size">
          <div className="section">
            <div className="block">
            <div className="title">
              Footer Logo Size
            </div>
            <Slider
                  className="rasa-slider"
                  buttons={true}
                  step={25}
                  defaultValue={50}
                  min={25}
                  max={175}
                  onChange={(e) => {
                    this.props.onChange(EmailAttributes.footerLogoSize, `${e.value}px`)
                  }}>
              <SliderLabel position={25}>25px</SliderLabel>
              <SliderLabel position={50}></SliderLabel>
              <SliderLabel position={75}>75px</SliderLabel>
              <SliderLabel position={100}></SliderLabel>
              <SliderLabel position={125}>125px</SliderLabel>
              <SliderLabel position={150}></SliderLabel>
              <SliderLabel position={175}>175px</SliderLabel>
            </Slider> <br /> <br />
          </div>
          <div className="logo-uploader">
            <div className="small-title">
              Footer Logo
            </div>
            <div className="description">
              Header logo is displayed by default. <br/>
              Upload a different logo for your footer here.
            </div>
            <div className="header-image-container">
              {this.state.loadingImage ? <Loading size={SIZES.LARGE}/> :
              <Dropzone onDrop={(acceptedFiles) => this.uploadImage(acceptedFiles[0])}
                        ref={dropzoneRef}
                        accept="image/*">
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()} className="lined-box clickable-item">
                        <input {...getInputProps()} />
                        <div className="image-or-placeholder-wrapper">
                          {this.hasFooterLogo() ?
                          <img src={this.getFooterImageUrl()}/> :
                          <div className="placeholder-wrapper">
                            <div className="upload-wrapper">
                              <div className="upload"><Upload/></div>
                            </div>
                            <p className="row1-text">Choose your file</p>
                            <p className="row2-text">You may drag and drop your file as well.</p>
                            <p className="row3-text">Upload only PNG, JPG.</p>
                          </div>}
                        </div>
                      </div>
                    </section>
                  )}
              </Dropzone>}
            </div>
            {this.hasFooterLogo() &&
            <div className="put-flex footer-logo-put">
              <div className={`delete-image-button ${this.hasFooterLogo() ? 'clickable-item' : ''}`}
                   onClick={this.deleteImage}>
                Delete
              </div>
              <div className="change-image-button"
                    onClick={this.openDialog}>
                Change
              </div>
            </div>}
            <div className="bottom-wrapper">
                <div className="small-title">
                  Link to
                </div>
                <div className="description">
                  Enter the URL you want this image to link to.
                </div>
            <div className="url-rectangle-wrapper">
              <div className="url-left-element">
                <div className="url-left-element-text">
                  URL
                </div>
              </div>
                <div className="url-right-element">
                <Input value={getAttribute(this.props.data, EmailAttributes.brandFooterUrl) || ''}
                      onChange={(e) =>
                        this.props.onChange(EmailAttributes.brandFooterUrl, e.target.value)}/>
                  <br></br>
                  </div>
                </div>
              </div>
              <br/>
              <div className="bottom-wrapper">
                <div className="small-title">
                  Alt Text
                </div>
                <div className="url-rectangle-wrapper">
                  <div className="url-right-element">
                    <Input value={getAttribute(this.props.data, EmailAttributes.brandFooterAltText) || ''}
                           onChange={(e) =>
                               this.props.onChange(EmailAttributes.brandFooterAltText, e.target.value)}/>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <div className="small-title">
              Font Color
            </div>
            <SelectColor
                key={EmailAttributes.footerFontColor}
                className={'footerFontColor'}
                selectColor={(c: string) => this.props.onChange(EmailAttributes.footerFontColor, c)}
                color={this.props.data[EmailAttributes.footerFontColor]}/>
          </div>
          <div onClick={() => this.props.push('/settings#company_profile')}>
                <br/>
                <a href="#">Update your company information</a>
            </div>
        </div>
      </div>
    </div>)
  }

  private getFooterImageUrl() {
    const imageUrl = getAttribute(this.props.data, EmailAttributes.brandFooterImageUrl) !== IMAGE_REMOVED ?
      getAttribute(this.props.data, EmailAttributes.brandFooterImageUrl) ||
      getAttribute(this.props.data, EmailAttributes.brandHeaderImageUrl) : ''
    return imageUrl ? sizedUrl(imageUrl, 150, 300, false) : ''
  }

  private hasFooterLogo() {
    return (!isNil(getAttribute(this.props.data, EmailAttributes.brandFooterImageUrl)) ||
      !isNil(getAttribute(this.props.data, EmailAttributes.brandHeaderImageUrl))) &&
      getAttribute(this.props.data, EmailAttributes.brandFooterImageUrl) !== IMAGE_REMOVED
  }

  private openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  private deleteImage = () => {
    this.props.onChange(EmailAttributes.brandFooterImageUrl, IMAGE_REMOVED)
    this.props.onChange(EmailAttributes.brandFooterUrl, '')
  }

  private canRemoveBranding = () => {
    return !BillingPlan.isFreePlan(this.state.plan) || BillingPlan.canRemoveFooterBranding(this.state.plan)
  }

  private removeBranding = () => {
    if (!this.canRemoveBranding()) {
      return (
        <div className="section remove-branding disabled-branding">
          <br/>
          <div className="small-title">Remove {BRAND_NAME} branding</div>
          <p className="remove-plan-words">To remove our branding in your emails,&nbsp;
            <UpgradeAnchorLink source={GA.UpgradeSource.FooterLogo}>Upgrade</UpgradeAnchorLink>
          </p>
          <ToggleOn/>
          {this.showBrandLogo() && <RasaLogo/>}
        </div>
      )
    } else {
      return (
        <div className="section remove-branding">
          <br/>
          <div className="small-title">Remove {BRAND_NAME} branding</div>
          <p className="remove-plan-words">Turn our branding on and off here.</p>
          <div onClick={(e) => this.toggle(e)} className="toggle-active">
            <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
              {isTruthy(this.props.data.show_rasa_logo) ? <ToggleOn/> : <ToggleOff/>}
            </button>
            {this.showBrandLogo() && <RasaLogo/>}
          </div>
        </div>
      )
    }
  }

  private uploadImage(image: any) {
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    this.setState({
      imageLoadingError: false,
      loadingImage: true,
    })
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage) => {
        this.setState({
          loadingImage: false,
        })
        this.props.onChange(EmailAttributes.brandFooterImageUrl, hostedImage.url)})
      .catch((error) => {
        this.setState({
          imageLoadingError: true,
        })
      })
  }

  private toggle = (e: any) => {
    const rasaLogoIsActive: boolean = !isTruthy(this.props.data.show_rasa_logo)
    if (this.props.onChange) {
      this.props.onChange(EmailAttributes.showRasaLogo, rasaLogoIsActive ? 1 : 0)
    }
  }

  private mergeContactUrl = (e: any) => {
    if (validateEmailAddress(e.target.value).valid) {
      this.props.onChange(EmailAttributes.contactUsUrl, 'mailto:' + e.target.value)
    } else {
      this.props.onChange(EmailAttributes.contactUsUrl, e.target.value)
    }
  }

  private getContactUrl = (url: string) => {
    return (url || '').replace('mailto:', '')
  }

  private linkComponentInfo = {
    contact: {
      title: 'Contact',
      url: {
        onChange: this.mergeContactUrl,
        placeholder: 'https://example.com or abc@example.com',
        validation: validateOptionalUrlOrEmailAddress,
        getValue: () => this.getContactUrl(getAttribute(this.props.data, EmailAttributes.contactUsUrl)),
      },
      text: {
        onChange: (e) => this.props.onChange(EmailAttributes.contactUsUrlText, e.target.value),
        placeholder: 'Contact',
        validation: noValidationNeeded,
        getValue: () => this.getContactUrl(getAttribute(this.props.data, EmailAttributes.contactUsUrlText)),
      },
    },
    feedback: {
      title: 'Feedback',
      text: {
        onChange: (e) => this.props.onChange(EmailAttributes.feedbackUrlText, e.target.value),
        placeholder: 'Report Content',
        validation: noValidationNeeded,
        getValue: () => getAttribute(this.props.data, EmailAttributes.feedbackUrlText),
      },
    },
    learnMore: {
      title: 'Learn More',
      url: {
        onChange: (e) =>  this.props.onChange(EmailAttributes.learnMoreUrl, e.target.value),
        placeholder: 'https://example.com',
        validation: validateOptionalUrl,
        getValue: () => getAttribute(this.props.data, EmailAttributes.learnMoreUrl),
      },
      text: {
        onChange: (e) => this.props.onChange(EmailAttributes.learnMoreUrlText, e.target.value),
        placeholder: 'Learn More',
        validation: noValidationNeeded,
        getValue: () => getAttribute(this.props.data, EmailAttributes.learnMoreUrlText),
      },
    },
    subscribe: {
      title: 'Subscribe',
      url: {
        onChange: (e) => this.props.onChange(EmailAttributes.subscribeUrl, e.target.value),
        placeholder: 'https://example.com',
        validation: validateOptionalUrl,
        getValue: () => getAttribute(this.props.data, EmailAttributes.subscribeUrl),
      },
      text: {
        onChange: (e) => this.props.onChange(EmailAttributes.subscribeUrlText, e.target.value),
        placeholder: 'Subscribe',
        validation: noValidationNeeded,
        getValue: () => getAttribute(this.props.data, EmailAttributes.subscribeUrlText),
      },
    },
    unsubscribe: {
      title: 'Unsubscribe',
      text: {
        onChange: (e) => this.props.onChange(EmailAttributes.unsubscribeUrlText, e.target.value),
        placeholder: 'Unsubscribe',
        validation: noValidationNeeded,
        getValue: () => getAttribute(this.props.data, EmailAttributes.unsubscribeUrlText),
      },
    },
  }

  private linkComponent(type) {
    const info = this.linkComponentInfo[type]
    return info ?
      <div className="link-component-container">
        <span>{`${info.title}:`}</span>
        {!!info.url &&
        <div className="url-rectangle-wrapper">
          <div className="url-left-element-text">
            URL
          </div>
          <div className={info.url.validation(info.url.getValue()).valid ? 'url-right-element' : 'url-right-element invalid'}>
          <Input value={info.url.getValue() || ''}
                onChange={info.url.onChange}
                placeholder={info.url.placeholder}/>
          <br></br>
          </div>
        </div>
        }
        <div className="url-rectangle-wrapper">
          <div className="url-left-element-text">
            TEXT
          </div>
          <div className={'url-right-element'}>
          <Input value={info.text.getValue() || ''}
                onChange={info.text.onChange}
                placeholder={info.text.placeholder}/>
          <br></br>
          </div>
        </div>
      </div> :
      null
  }

   private showBrandLogo = () => this.state.partnerIdentifier === DEFAULT_PARTNER_IDENTIFIER
}

export const FooterEdit = connect(
  null,
  {
    push: Router.push,
  },
)(FooterEditComponent)
